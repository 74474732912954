/*jshint esversion: 6 */

import global from "./global.js";
import storage from "./storage.js";
import * as tools from "./tools.js";
import t from "./translate.js";
import * as init_plus from './init_plus.js';

export function doLogin(){
  // jQuery('.left').html('');

  var html='';

  html+='<div class="page">';
    html+='<div class="page-content login-screen-content">';
      html+='<div class="login-screen-title">'+t('Login')+'</div>';

      if (global.useSocialLogin) {
        html+='<div class="list" style="margin-top: 120px; margin-bottom: 120px;">';
          html+='<ul>';
            // html+='<li class="item-link">';
            //   html+='<button id="facebookLogin" class="col button button-large "><i class="f7-icons">logo_facebook</i> Login With Facebook</button>';
            // html+='</li>';
            html+='<li style="margin:15px;" class="item-link">';
              html+='<button style="border-radius:25px;" id="googleLogin" class="col button button-large button-fill"><i class="f7-icons color-white" style="margin-top: 8px; float:left;">logo_google</i> <span style="font-size: 30px;">'+t('Accedi con Google')+'</span></button>';
            html+='</li>';
          html+='</ul>';
        html+='</div>';


        html+=`
        <div style="text-align: center; ">
          <span style="color: #aaa;">${t('oppure')}</span>
        </div>
        `;
      }

      if (global.use_oauth2) {
        html+='<div class="list" style="margin-top: 120px; margin-bottom: 120px;">';
          html+='<ul>';
            // html+='<li class="item-link">';
            //   html+='<button id="facebookLogin" class="col button button-large "><i class="f7-icons">logo_facebook</i> Login With Facebook</button>';
            // html+='</li>';
            html+='<li style="margin:15px;" class="item-link">';
              html+='<button style="border-radius:25px;" id="oauthLogin" class="col button button-large button-fill"><i class="f7-icons color-white" style="margin-top: 8px; float:left;"></i> <span style="font-size: 30px;">'+t('Accedi con Oauth')+'</span></button>';
            html+='</li>';
          html+='</ul>';
        html+='</div>';

        if(!global.skip_classic_login){
          html+=`
          <div style="text-align: center; ">
            <span style="color: #aaa;">${t('oppure')}</span>
          </div>
          `;
        }
      }

      if(!global.skip_classic_login){

        html+='<form>';
          html+='<div class="list">';
            html+='<ul>';
              html+='<li class="item-content item-input">';
                html+='<div class="item-inner">';
                  html+='<div class="item-title item-label">Username</div>';
                  html+='<div class="item-input-wrap">';
                    html+='<input autocapitalize="off" autocorrect="off" type="text" name="username" id="username" placeholder="Your username">';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
              html+='<li class="item-content item-input">';
                html+='<div class="item-inner">';
                  html+='<div class="item-title item-label">Password</div>';
                  html+='<div class="item-input-wrap">';
                    html+='<input autocapitalize="off" autocorrect="off" type="password" name="password" id="user_password" placeholder="Your password">';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
  
              html+='<li class="item-content item-input">';
                html+='<p style="color:#a9a9a9"><label class="checkbox"><input id="showPassword" type="checkbox"><i class="icon-checkbox"></i></label><i> '+t('show_password')+'</i></p>';
              html+='</li>';
  
  
            html+='</ul>';
          html+='</div>';
          html+='<div class="list">';
            html+='<ul>';
              html+='<li><a class="item-link list-button" id="submitLogin">'+t('Accedi')+'</a></li>';
            html+='</ul>';
          html+='</div>';
        html+='</form>';
      }


    html+='</div>';
  html+='</div>';

  jQuery('#f7_content').html(html);
  jQuery('#footer').html("");

  jQuery("#facebookLogin").click(function() {
    console.log("fb");
    loginWithFacebook();
  });
  jQuery("#googleLogin").click(function() {
    console.log("google");
    if (global.is_cordova()) {
      loginWithGoogle();
    }
    else {
      webGoogleLogin();
    }
  });

  jQuery("#oauthLogin").click(function() {
    console.log("oauth");
    webOauthLogin();
  });


  jQuery("#showPassword").change(function() {
    var x = document.getElementById("user_password");
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
  });

  var s=storage.get();

  jQuery("#submitLogin").click(function() {

    try {
      global.app.dialog.preloader(global.loadingText);
    }
    catch (e) {
      alert("Errore Try CAtch");
    }


    var urladdr = global.base_call+"token";
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        grant_type:'password',
        username:jQuery('#username').val(),
        password:jQuery('#user_password').val(),
        client_id:'smb-portal'
      },
      dataType: "json",
      success: function(user){
        global.app.dialog.close();

        if (user!=null && user.ok) {
          console.log(user);
          loadUserjStorage(user);
        }
        else {
          global.app.dialog.alert("Username e/o password errati. Riprovare.", "Errore nell'accesso");
        }
      },
      error: function(e,status,error){
        global.app.dialog.close();
        global.app.dialog.alert("Si è verificato un errore nella chiamata. Questo errore non dipende da te.", "Errore nell'API");
        console.log(e);
      },
    });

  });
}

// -----------GOOGLE WEB LOGIN INIZIO ------------------

function webGoogleLogin(){

  console.log("WEB LOGIN");

  window.location.href=GetOAuthURL();

  // // Check if page is secure
  // if (IsSecure()) {
  //
  // }
}


function  webOauthLogin(){

  console.log("WEB OAUTH2");
  if(global.use_oauth2){
    window.location.href=GetOAuthURLNew(global.use_oauth2);
  }
  else{
    alert("oauth2 not configured");
  }

}


// Function for building the oauth url for the authentication link
export function GetOAuthURLNew(oauth_param) {

  // Set basic variables

  var redirect_uri=location.origin;
  if (oauth_param.redirect_uri) {
    redirect_uri=oauth_param.redirect_uri;
  }

  var redirect_url = [redirect_uri].join('');
  var oAuthEndPoint = oauth_param.endpoint+"auth";
  var oAuthClientID = oauth_param.client_id;
  var oAuthScope="email"
  if (oauth_param.scope) {
    oAuthScope=oauth_param.scope;
  }

  // URL Encode parameters
  var redirect_uri = encodeURIComponent(redirect_url); // Get current URL
  var client_id = encodeURIComponent(oAuthClientID);
  var scope = encodeURIComponent(oAuthScope);


  var response_type="code"
  if (oauth_param.response_type) {
    response_type=oauth_param.response_type;
  }

  // Build the actuall url
  var oauth_url = oAuthEndPoint + "?client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&scope=" + scope + "&response_type="+response_type;

  console.log("oAuth2 URL: "+oauth_url);
  return oauth_url;
}



// Function for building the oauth url for the authentication link
export function GetOAuthURLGeneric(oauth_param) {

  // Set basic variables

  var redirect_uri=location.origin;
  if (oauth_param.redirect_uri) {
    redirect_uri=oauth_param.redirect_uri;
  }

  var redirect_url = [redirect_uri].join('');
  var oAuthEndPoint = oauth_param.endpoint;
  var oAuthClientID = oauth_param.client_id;
  var oAuthScope="email"
  if (oauth_param.scope) {
    oAuthScope=oauth_param.scope;
  }

  // URL Encode parameters
  var redirect_uri = encodeURIComponent(redirect_url); // Get current URL
  var client_id = encodeURIComponent(oAuthClientID);
  var scope = encodeURIComponent(oAuthScope);


  var response_type="token"
  if (oauth_param.response_type) {
    response_type=oauth_param.response_type;
  }

  // Build the actuall url
  var oauth_url = oAuthEndPoint + "?client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&scope=" + scope + "&response_type="+response_type;

  console.log("oAuth2 URL: "+oauth_url);
  return oauth_url;
}


// Function for building the oauth url for the authentication link
function GetOAuthURL() {

    // Set basic variables

    var redirect_uri=location.origin
    if (global.redirect_uri) {
      redirect_uri=global.redirect_uri;
    }
    var redirect_url = [redirect_uri].join('');
    var oAuthEndPoint = "https://accounts.google.com/o/oauth2/auth";
    var oAuthClientID = "53405368259-k585l8fv4iefejh6a36enm021ci9sigf.apps.googleusercontent.com";
    var oAuthScope = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";


    // URL Encode parameters
    var redirect_uri = encodeURIComponent(redirect_url); // Get current URL
    var client_id = encodeURIComponent(oAuthClientID);
    var scope = encodeURIComponent(oAuthScope);

    // Build the actuall url
    var oauth_url = oAuthEndPoint + "?client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&scope=" + scope + "&response_type=token";

    console.log("oAuth URL: "+oauth_url);
    return oauth_url;
}

// export function LoadUserInfoOauth2(access_token, session_state) {

//   let oauth2_data={
//     accessToken:access_token,
//     session_state:session_state
//   };

//   socialLogin('oauth2',oauth2_data);

// }


export function LoadUserInfo(access_token, expires_in) {
    jQuery.ajax({
        url: 'https://www.googleapis.com/userinfo/v2/me',
        type: 'GET',
        dataType: 'json',
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        success: function (data) {

          var google_data={
            accessToken:access_token,
            userId:data.id,
            displayName:data.given_name+' '+data.family_name,
            email: data.email
          };

          socialLogin('google',google_data);

            // // Populate demo, img and name
            // jQuery("#user_pic").attr("src", data.picture);
            // jQuery("#user_name").attr("href", data.link);
            // jQuery("#user_name").text(data.name);
            //
            // // Show raw data
            // for (var property in data) {
            //     if (data.hasOwnProperty(property)) {
            //         jQuery("#raw_data").append("<b>" + property + ":</b>" + data[property] + "<br/>");
            //     }
            // }
            //
            //

        },
        error: function () {
            jQuery('#demo').html('<p>An error has occurred</p>');
        }
    });
}

// Function for extracting URL parameters returned to the page after oauth

// -----------GOOGLE WEB LOGIN FINE ------------------

function loadUserjStorage(user,social_token, refresh_token){

  if(user){
    var s=storage.get();
    console.log("---------------");
    console.log(user);
    console.log("---------------");
    s.user={
      access_token:user.access_token,
      refresh_token:refresh_token,
      social_token:social_token,
      uid:user.user.uid,
      username:user.user.name.split('-')[0],
      roles:user.user.roles,
    };
    if (user.user.init) {
      s.user.email=user.user.init;
    }
    else {
      s.user.email=user.user.mail;
    }
    storage.save(s);

    console.log(user);

    tools.register();

    location.hash='tools/sync';


    setTimeout(function(){
      
      location.reload(false);
    }, 100);
  }
  else{
    alert("Error in login");
  }
}

function loginWithFacebook(){
  if (typeof facebookConnectPlugin!='undefined') {
    facebookConnectPlugin.login(["public_profile","email"],function(result){
      console.log(JSON.stringify(result));

      facebookConnectPlugin.api("/me?fields=email,name,picture",["public_profile","email"], function(userData){
        // alert(JSON.stringify(userData));
        socialLogin('facebook',result,userData);
      },function(error){
        alert(JSON.stringify(error));
      });


    },function(error){
      alert(JSON.stringify(error));
    });
  }
  else {
    alert("Plugin facebook login assente");
  }
}

function loginWithGoogle(){
  if (typeof window.plugins!='undefined' && typeof window.plugins.googleplus!='undefined') {
//
    window.plugins.googleplus.login({

    },function(result){
      socialLogin('google',result);
      // alert(JSON.stringify(result));
    },function(error){
      alert(JSON.stringify(error));
    });
  }
  else {
    alert("Plugin google login assente");
  }
}


function socialLogin(provider,result,userData){

  var token=null;
  var name=null;
  var user_id=null;
  var email=null;

  var payload_input={};

  if (provider=='facebook') {
    token=result.authResponse.accessToken;
    user_id=result.authResponse.userID;
    name=userData.name;
    email=userData.email;

    payload_input={
      social_provider:provider,
      social_token:token,
      social_uid:user_id,
      social_name:name,
    };
  }
  else if (provider=='google') {
    token=result.accessToken;
    user_id=result.userId;
    name=result.displayName;
    email=result.email;
    payload_input={
      social_provider:provider,
      social_token:token,
      social_uid:user_id,
      social_name:name,
    };
  }
  else if (provider=='oauth2'){

    // debugger;

    payload_input={
      social_provider:provider,
      social_token:result.accessToken,
      refresh_token:result.refreshToken,
      social_uid:"xxx",
      social_name:null,
      client_id: global.use_oauth2.client_id,
      endpoint: global.use_oauth2.endpoint
    };

  }

  global.app.dialog.preloader(global.loadingText);
  var urladdr = global.base_call+"social_login";
  // alert(urladdr);
  jQuery.ajax({
    type: "GET",
    url: urladdr,
    data: payload_input,
    dataType: "json",
    success: function(data){
      global.app.dialog.close();

      // alert(JSON.stringify(data));
      //
      // console.log("---");
      // console.log(data);
      // console.log("---");


      // debugger;

      loadUserjStorage(data.token,payload_input.social_token,payload_input.refresh_token);
      // examplePrivateAPI(data.token.access_token);
    },
    error: function(e){
      global.app.dialog.close();
      console.log(e);
    },
  });
}

export function getTokensOauth2(code){
  var oauth_param=global.use_oauth2;
  var redirect_uri=location.origin;
  if (oauth_param.redirect_uri) {
    redirect_uri=oauth_param.redirect_uri;
  }

  var oAuthEndPoint = oauth_param.endpoint+"token";
  var oAuthClientID = oauth_param.client_id;
  var oAuthClientSecret = oauth_param.client_secret;

  jQuery.ajax({
    type: "POST",
    url: oAuthEndPoint,
    data: {
      grant_type:'authorization_code',
      code:code,
      client_id: oAuthClientID,
      client_secret:oAuthClientSecret,
      redirect_uri:redirect_uri,
    },
    dataType: "json",
    contentType: "application/x-www-form-urlencoded",
    success: function(tokens){
      console.log(tokens);
      
      // debugger
      let oauth2_data={
        accessToken:tokens.access_token,
        refreshToken:tokens.refresh_token,
        session_state:tokens.session_state
      };
    
      // Va fatta solo sulle app vecchie non su PLUS
      if(global.plus){
        init_plus.firstApi(oauth2_data);
      }
      else{
        socialLogin('oauth2',oauth2_data);
      } 
        
      
    },
    error: function(e){
      console.log(e);
    }
  });
}


export function doLogout(){
  var html='';

  html+='<div class="page">';
    html+='<div class="page-content login-screen-content">';
      html+='<div class="login-screen-title">'+t('Logout')+'</div>';
      html+='<form>';
        html+='<h3 style="text-align:center;">';
          html+='Sei sicuro di voler fare il logout?';
        html+='</h3>';
        html+='<div class="list">';
          html+='<ul>';
            html+='<li><a class="item-link list-button" id="submitLogout">Esci</a></li>';
          html+='</ul>';
        html+='</div>';

      html+='</form>';
    html+='</div>';
  html+='</div>';

  jQuery('#f7_content').html(html);
  jQuery('#footer').html("");

  jQuery("#submitLogout").click(function() {
    var s=storage.get();
    var refresh_token=s.user.refresh_token;

    delete s.user;
    delete s.res;
    delete s.id_farm;
    delete s.all_farms;
    

    storage.save(s);
    location.hash='';

    if(global.plus){

    }
    else{
      tools.register();
    }
    
    if(global.use_oauth2){
      jQuery.ajax({
        type: "POST",
        url: global.use_oauth2.endpoint+'logout',//?client_id='+global.use_oauth2.client_id+'&client_secret='+global.use_oauth2.client_secret+'&refresh_token='+access_token,
        data:{
          client_id: global.use_oauth2.client_id,
          client_secret: global.use_oauth2.client_secret,
          refresh_token: refresh_token
        },
        dataType: "json",
        contentType: "application/x-www-form-urlencoded",
        success: function(data){
          console.log(data);
        },
        error: function(e){
          console.log(e);
        },
      })
    }
    else{
      setTimeout(function(){
        location.reload(false);
      }, 10);
    }
  });
}

// function examplePrivateAPI(token){
//   alert('Token:'+token);
//   jQuery.ajax({
//     type: 'GET',
//     data:{
//       access_token:token
//     },
//     url: global.base_call + 'get_users',
//     dataType: "json",
//     success: function(res){
//       alert("OK");
//       alert(JSON.stringify(res));
//     }
//   });
// }
