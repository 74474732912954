import global from './global.js';
import storage from './storage.js';
import t from './translate.js';
import exe_routes from "./routes.js";
import * as tools_routes from "./tools.js";



export default  function init_app_plus(theme, project){
  global.plus=true;

  init_app_internal(theme, project);

  // console.log(project);
  global.theme=theme;
  global.project=project;
  global.loadingText=t('Caricamento in corso..');
  global.lockApp=false;

  if (typeof global.settingsName=='undefined') {
    global.settingsName='template_settings';
  }

  console.log(global.language);
  if (typeof global.language=='undefined') {
    global.language='en';
  }

  // console.log($);
  // console.log($.jStorage);
  global.jQuery=jQuery;
  if(jQuery){
    jQuery.jStorage=$.jStorage;
    // console.log(jQuery.jStorage);
  }

  storage.init({});

  var s=storage.get();
  if (s.language) {
    global.language=s.language;
  }

  console.log("start plus");

  var oauth2_data={
    accessToken:s.user.access_token,
    refreshToken:s.user.refresh_token,
  }
  firstApi(oauth2_data);
}

export function firstApi(oauth2_data){
  var s=storage.get();

  var headers={};
  headers.Authorization='Bearer '+oauth2_data.accessToken;

  let toSync=false;
  if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
    toSync=true;
  }

  var aData = {};
  aData.id_device = s.id_device;
  aData.platform = s.platform;
  aData.model = s.model;
  aData.version_os = s.version_os;
  aData.app_version = s.version;
  aData.app_code = s.app_code;
  aData.notification_token = s.notification_token;

  let request={
    app_version: global.app_version,
    refresh_token: oauth2_data.refreshToken,
    notification:{
      token:s.notification_token,
      error:s.notification_token_error?s.notification_token_error:null,
    },
    // notification_token: s.notification_token,
    toSync:toSync,
    info: aData
  };
  

  console.log('Headers', headers);
  jQuery.ajax({
    method: "POST",
    contentType: "application/json; charset=utf-8",
    url: global.base_call+"first_api",
    data:JSON.stringify(request),
    dataType: "json",
    headers:headers,
    success: function (data) {
      if(data.ok){
        s.user={
          access_token:data.user.access_token,
          refresh_token:oauth2_data.refreshToken,
          social_token:oauth2_data.accessToken,
          uid:data.user.uid,
          realname:data.user.superuser.realname,
          username:data.user.superuser.name,
          roles:data.user.roles,
          email:data.user.superuser.mail
        };
  
        storage.save(s);
        console.log(s.user);
  
        handlePermission();
  
        console.log(data);
  
        if(data.toUpdateApp){
          updateAppVersion();
        }
  
        if(location.search){
          window.location.href =  window.location.href.split("?")[0]+'#'; //"http://www.newurl.com";
        }
        else {
          location.hash='';
        }  
      }
      else {
        console.log(data);
        if(data.doLogin){
          location.hash='login';
        }
      }
      // location.reload(false);

    },
    error: function (e) {
      console.log(e);
    }
  });

}

function handlePermission() {
    return navigator.permissions
            .query({name:'notifications'})
            .then(permissionQuery)
            .catch(permissionError);
}

function permissionQuery(result) {
  // debugger
    console.debug({result});
    var newPrompt;

    if (result.state == 'granted') {
      var settings={};
      // notifications allowed, go wild
      // console.log("GLN",global.notification.getToken);
      var s=storage.get();
      if(s && (typeof s.notification_token == 'undefined' || s.notification_token==null) ){
        // se le notifiche sono attive e il token non esiste
        console.log("Prende il token");
        global.notification.getToken(function(token){
          console.log('michele',token, settings);
          settings.notification_token = token;

          var storage = storage.get();
          settings=jQuery.extend({},storage, settings);
          storage.save(settings);
          return settings;
        }, function(error){
          alert(error);
          return settings;
        });
      }
    } else if (result.state == 'prompt') {
        // we can ask the user
        newPrompt = Notification.requestPermission();

    } else if (result.state == 'denied') {
      var s=storage.get();
      s.notification_token=null
      s.notification_token_error='Notifications denied';
      storage.save(s);
      // notifications were disabled
      alert(t('Per favore attiva le notifiche per poter ricevere le notifiche di questo sito'));
    }

    result.onchange = () => console.debug({updatedPermission: result});

    return newPrompt || result;
}

function permissionError(error) {
    console.error(error);
}


//funzione chiamata dall'html
function init_app_internal(theme, project){
  caches.keys().then(function(cacheNames) {
    jQuery('#debug').append('<br><b>Caches:<b>');
    cacheNames.forEach(function(cacheName) {
      console.log("CACHE NAME: "+cacheName);
      jQuery('#debug').append('<br>'+cacheName);
    });
  });

  if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('/sw.js')
			.then((reg) => {
				console.log('SW registered!', reg);
				jQuery('#debug').append('<br>SW registered!');

        //update every minute
				setInterval(function () {
					console.log('SW : check for update');
					reg.update();
				}, 2000);
			})
			.catch((err) => {
				console.log('Boo!', err);
				jQuery('#debug').append('<br>Boo! SW not registered!');
			});


		//check if there is a new service worker
		navigator.serviceWorker.addEventListener(
			'controllerchange',
			function (event) {
				console.log('SW: controllerchange!');
          
        var html='<button class="refresh_page button button-primary" >Ricarica pagina</button>';
        jQuery('#footer').html(html);
        jQuery('#debug').append(html);

        jQuery('.refresh_page').on('click',function(){
          jQuery('#debug').append('<br>Sto ricaricando la pagina adesso...');
          location.reload();
          jQuery('#debug').append('<br>Ho lanciato il comando... aspetta');
        });
 			}
		);
	}



  //attiva crashalitic per android
  if (global.is_cordova()) {
    if (typeof FirebaseCrashlytics!=='undefined') {
      global.crashlytics = FirebaseCrashlytics.initialise();
    }
  }

  //variabili globali
  global.theme=theme;
  global.project=project;
  global.loadingText=t('Caricamento in corso..');
  //variabile per bloccare l'app per l'aggiornamento forzato
  global.lockApp=false;

  //setting di jstorage
  if (typeof global.settingsName=='undefined') {
    global.settingsName='template_settings';
  }

  console.log(global.language);
  if (typeof global.language=='undefined') {
    global.language='en';
  }

  //Attiva jquery e jquery.storage
  global.jQuery=jQuery;
  if(jQuery){
    jQuery.jStorage=$.jStorage;
  }

  //Inizializza lo storage
  storage.init({});

  //usa la lingua definita dall'utente
  let s=storage.get();
  if (s.language) {
    global.language=s.language;
  }

  //verifica se il porgetto prevede delle sidebars
  let theme_param={'div_id':'body_div', 'title': ''};
  if(typeof project.get_theme_param=='function'){
    theme_param = project.get_theme_param(theme_param);
    console.log(theme_param.sidebars);
    let tools={'label': t('Impostazioni'), 'icon_f7':'gear', 'href':'#tools'};
    if (theme_param.showTools!==false) {
      theme_param.sidebars.push(tools);
    }
  }

  //Crea la pagina html scheletro dell'app
  theme.init(theme_param);

  //TODO verificare!
  checkVersion();

  if (!global.lockApp) {
    //aggancia tutte le route
    exe_routes();
  }

  // tools_routes.register();



  // try {
  //   var urladdr="manifest.json";
  //   if (!global.is_cordova()) {
  //     if (typeof url!='undefined') {
  //       urladdr=url;
  //     }
  //   }
  //   console.log(urladdr);
  //   jQuery.ajax({
  //     url: urladdr,
  //     dataType:'text',
  //     success: function(text) {
  //       var manifest = JSON.parse(text);
  //       console.log("MANIFEST",manifest);

  //       if ((!s.installationDialog || s.installationDialog!='done' ) && global.iOS()) {
  //         setTimeout(function() {
  //           var installDialog = global.app.dialog.create({
  //             title:`${t('Installa')} ${manifest.name}`,
  //             destroyOnClose:true,
  //             buttons:[{
  //               close:true,
  //               text:t('Chiudi'),
  //               onClick:function(){
  //                 var s=storage.get();
  //                 s.installationDialog='done';
  //                 storage.save(s);
  //               }
  //             }],
  //             text: `
  //             1. ${t('Clicca su')} "<i class="f7-icons">square_arrow_up</i>"
  //             <br>
  //             2. ${t('Seleziona')} "<b>${t('Aggiungi alla schermata Home')} <i class="f7-icons">plus_square</i></b>"
  //             `,
  //             closeByBackdropClick: true,
  //             cssClass: 'iOSinstallPWA'
  //           });

  //           installDialog.open(true);

  //         }, 6000);
  //       }

  //       if (manifest.name) {
  //         document.title = manifest.name;
  //       }
  //       if (manifest.theme_color) {
  //         var metaThemeColor = document.querySelector("meta[name=theme-color]");
  //         metaThemeColor.setAttribute("content", manifest.theme_color);
  //       }

  //     },
  //     error:function(e){
  //       console.log("errore nel caricamento del manifest...");
  //     }
  //   });

  // }
  // catch (e) {
  //   console.log("manifest non andato a buon fine!");
  // }

  // if (s.user && ( !s.lastUpdate || s.lastUpdate < (new Date().getTime()-86400000) ) ) {
  //   location.hash='tools/sync';
  // }

}


function checkVersion(){
  // if (global.is_cordova() && cordova.platformId == 'android') {
  //   cordova.getAppVersion.getVersionNumber().then(function (local_version) {
  //     cordova.getAppVersion.getVersionCode(function(local_code){
  //       console.log("LOCAL CODE: "+local_code);
  //       console.log("LOCAL VERSION: "+local_version);

  //       var s=storage.get();
  //       var storage_version=s.version;

  //       console.log("STORAGE VERSION: "+storage_version);

  //       var firstTimeInThisVersion=true;
  //       if (typeof s.firstTimeInThisVersion!='undefined') {
  //         firstTimeInThisVersion=s.firstTimeInThisVersion;
  //       }

  //       if (storage_version!=local_version) {
  //         firstTimeInThisVersion=true;

  //         s.version=local_version;
  //         s.app_code = local_code;
  //         storage.save(s);
  //       }

  //       jQuery.ajax({
  //         type: 'GET',
  //         url: global.base_call+"get_app_version",
  //         dataType: "json",
  //         success: function(server_version){
  //           console.log("SERVER VERSION:",server_version);

  //           var release_note=server_version.data[0].release_note;
  //           var release_date=server_version.data[0].release_date;
  //           server_version=server_version.data[0].version;

  //           console.log("SERVER VERSION:",server_version);

  //           var changeVersion=false;

  //           if (typeof local_version!='undefined'){
  //             if (local_version!=server_version) {
  //               changeVersion=true;
  //             }
  //           }
  //           else {
  //             changeVersion=true;
  //           }

  //           var mainNumberSV=server_version.split('.')[0];
  //           var primaryNumberSV=server_version.split('.')[1];
  //           var secondaryNumberSV=server_version.split('.')[2];

  //           var mainNumberLV=local_version.split('.')[0];
  //           var primaryNumberLV=local_version.split('.')[1];
  //           var secondaryNumberLV=local_version.split('.')[2];

  //           if (parseInt(mainNumberSV)>parseInt(mainNumberLV) || parseInt(primaryNumberSV)>parseInt(primaryNumberLV)) {
  //             forceUpdate();
  //           }
  //           else if(parseInt(mainNumberSV)==parseInt(mainNumberLV) && parseInt(primaryNumberSV)==parseInt(primaryNumberLV) && parseInt(secondaryNumberSV) > parseInt(secondaryNumberLV)) {
  //             console.log("versione cambiata!");
  //             changedVersion();
  //           }
  //           else {
  //             console.log("versione non cambiata!");

  //             if (firstTimeInThisVersion==true) {
  //               console.log("prima volta nell'app");

  //               global.app.dialog.alert(release_note, "Note di rilascio", function(){location.reload();});

  //               s.release_date=formatDate(release_date);
  //               s.firstTimeInThisVersion=false;
  //               storage.save(s);
  //             }
  //           }
  //         },
  //         error: function(e) {
  //           console.log("Si è verificato un errore");
  //           // alert(e);
  //         }
  //       });
  //     });
  //   });
  // }
  // else {
  //   if (global.app_version) {
  //     var s=storage.get();
  //     s.version=global.app_version;
  //     storage.save(s);
  //   }
  // }
}

function updateAppVersion(){
  jQuery('#debug').append('<br>Nuova Versione disponibile!');
  // alert('updateAppVersion');
  // if ('serviceWorker' in navigator) {
  //   jQuery('#debug').append('serviceWorker in navigator!');

  //   // console.log(caches.keys());
  //   caches.keys().then(function(cacheNames) {
  //     cacheNames.forEach(function(cacheName) {
  //       jQuery('#debug').append('<br>'+cacheName+' cancellato (a regola)!');

  //       console.log("CACHE NAME: "+cacheName);
  //       caches.delete(cacheName);
  //     });
  //   });
  // }
  // else{
  //   jQuery('#debug').append('serviceWorker NOT in navigator!');
  // }
  
  // var s=storage.get();
  // jQuery('#debug').append('<br><br>'+JSON.stringify(s)+'');

  

  // caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
  // this._target.storageAgent().clearDataForOrigin(this._securityOrigin, storageTypes.join(','));

}