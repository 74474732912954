/*jshint esversion: 6 */

import * as Login from "./login.js";
import * as Tools from './tools.js';
import global from './global.js';

export default function exe_routes(){

  var root = null;
  var useHash = true; // Defaults to: false
  var hash = '#'; // Defaults to: '#'
  var router = new Navigo(root, useHash, hash);

  router.on(function () {
    if(global.project.home_page){
      global.app.ptr.destroy('.ptr-content');
      global.project.home_page();
      console.log(global.project);

      var html='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
      jQuery('.left').html(html);

      if (!global.dontCleanFooter) {
        jQuery('#footer').html('');
      }
    }
    else{
      console.warn("IMPLEMENTARE home_page nel progetto in corso");
    }
  }).resolve();

  router.on({
    'access_token=:access_token&token_type=:token_type&expires_in=:expires_in&scope=:scope*': function(param){
      console.log(param);
      console.log(param.access_token);
      Login.LoadUserInfo(param.access_token, param.expires_in);

    },
    'session_state=:session_state&code=:code*': function(param){
      // debugger;
      console.log("aaaaaa");

    },
    // 'session_state=:session_state&access_token=:access_token': function(param){
    //   console.log(param);
    //   // debugger;
    //   console.log(param.access_token);
    //   Login.LoadUserInfoOauth2(param.access_token, param.session_state);

    // },
    'login': function () {
      if(location.search){
        let code=location.search.split('code=')[1];
        Login.getTokensOauth2(code);
      }
      else{
        global.app.ptr.destroy('.ptr-content');
        Login.doLogin();
      }
    },
    'logout': function () {
      global.app.ptr.destroy('.ptr-content');
      Login.doLogout();
    },
    'my_profile': function (){
      global.app.ptr.destroy('.ptr-content');
      Tools.myProfile();
    },
    'tools': function () {
      global.app.ptr.destroy('.ptr-content');
      Tools.tools();
      if(global.project.tools){
        global.project.tools();
      }
    }
  });

  if(Tools.exe_routes){
    router=Tools.exe_routes(router);
  }

  if(global.project.exe_routes){
    router=global.project.exe_routes(router);
  }
  else{
    console.warn("IMPLEMENTARE exe_routes nel progetto in corso");
  }

  // console.log(router);
  router.resolve();
  return router;
}

  

